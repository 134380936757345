import React from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import { ImageBox, LeftTextSection, Text } from "./style";
import { TextSection, Title } from "./style";
import line23 from "../../Images/Line 23.svg";
import Group40130 from "../../Images/Group 40130.jpg";
import Group40133 from "../../Images/Group 40133.jpg";
import Group40118 from "../../Images/Group 40118.png";
import illustration from "../../Images/exec-illustration.png";
import teachillustration from "../../Images/teach-illustration.png";
import studentillustration from "../../Images/student-illustration.png";
import Features from "./Features";

function Products() {
  return (
    <Box sx={{ pt: 10, background: "#EEFFF9" }}>
      <Title>Our Products</Title>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <img width="30%" src={line23} alt="" />
      </Stack>
      <Text>
        We believe every learner is unique, so is their learning trajectory.
        Hence, we utilise the latest research in learning sciences, artificial
        intelligence and neuroscience to analyse how every learner interacts
        with and learns your content, on our customised platform. These powerful
        learning analytics are a first step towards contextualised learning
        experiences for each individual: learning the same content, but with
        different pedagogies in accordance with their needs.
      </Text>
      <Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
          }}
        >
          <img width="100%" src={Group40130} alt="" />
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
          }}
        >
          <img width="100%" src={Group40133} alt="" />
        </Box>
      </Box>
      <Grid
        container
        rowGap={15}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <ImageBox sx={{ margin: "5% auto" }} item md={5.8} xs={12}>
          <img width="80%" src={illustration} alt="" />
        </ImageBox>
        <TextSection item md={5.8} xs={12}>
          <Stack
            marginTop="-25px"
            marginLeft="40%"
            direction="row"
            height="50px"
            alignItems="center"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
          </Stack>
          <Stack
            height="90%"
            alignItems="flex-start"
            justifyContent="space-evenly"
          >
            <Title sx={{ textAlign: "left" }}>Naseem Executive</Title>
            <Box sx={{ textAlign: "left", width: "90%" }}>
              <Text
                sx={{
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  padding: "0px",
                  color: "#2B2B2B",
                }}
              >
                Accessing joint analytics and keeping track of all of your
                schools’ inventory and academic performance of students at each
                school in one place. Communication with staff and curriculum
                tracking made easy.
              </Text>
            </Box>
          </Stack>
        </TextSection>

        <LeftTextSection item md={5.8} xs={12}>
          <Stack
            marginTop="-25px"
            marginLeft="40%"
            direction="row"
            height="50px"
            alignItems="center"
          >
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
          </Stack>
          <Stack
            height="90%"
            alignItems="flex-end"
            justifyContent="space-evenly"
          >
            <Title sx={{ textAlign: "left" }}>Admin Portal</Title>
            <Box sx={{ textAlign: "left", width: "90%" }}>
              <Text
                sx={{
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  padding: "0px",
                  color: "#2B2B2B",
                }}
              >
                Admin portal provides a holistic overview of school’s financial,
                academic and inventory updates. It enables the leadership team
                to generate customised reports and communicate directly with
                teachers, learners and parents . We are using AI to enable more
                informed decision making through automated recommendations and
                predictive analytic
              </Text>
            </Box>
          </Stack>
        </LeftTextSection>
        <ImageBox sx={{ margin: "0 auto" }} item md={5.8} xs={12}>
          <img width="80%" src={Group40118} alt="" />
        </ImageBox>
        <ImageBox sx={{ margin: "0 auto" }} item md={5.8} xs={12}>
          <img width="80%" src={teachillustration} alt="" />
        </ImageBox>
        <TextSection item md={5.8} xs={12}>
          <Stack
            marginTop="-25px"
            marginLeft="40%"
            direction="row"
            height="50px"
            alignItems="center"
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
          </Stack>
          <Stack
            height="90%"
            alignItems="flex-start"
            justifyContent="space-evenly"
          >
            <Title sx={{ textAlign: "left" }}>Teacher Portal</Title>
            <Box sx={{ textAlign: "left", width: "90%" }}>
              <Text
                sx={{
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  padding: "0px",
                  color: "#2B2B2B",
                }}
              >
                Our teacher portal empowers teachers and helps with their
                workload through AI-powered lesson planning assistance,
                automated assessments, grading and customised recommendations
                for leaners. Teachers can also communicate with their learners,
                track their progress and share content in any format.
              </Text>
            </Box>
          </Stack>
        </TextSection>
        <LeftTextSection item md={5.8} xs={12} sx={{}}>
          <Stack
            marginTop="-25px"
            marginLeft="40%"
            direction="row"
            height="50px"
            alignItems="center"
          >
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
          </Stack>
          <Stack
            height="90%"
            alignItems="flex-end"
            justifyContent="space-evenly"
          >
            <Title sx={{ textAlign: "left" }}>Student Portal</Title>
            <Box sx={{ textAlign: "left", width: "90%" }}>
              <Text
                sx={{
                  letterSpacing: "0.05em",
                  textAlign: "left",
                  padding: "0px",
                  color: "#2B2B2B",
                }}
              >
                Our student portal is designed to set the foundations for an
                AI-powered personalised digital tutor of the future. It aims to
                identify the knowledge gaps and offer content recommendations
                based on learners’ unique learning styles. Students can also
                complete assignments, access learning content and review their
                attendance records.
              </Text>
            </Box>
            s
          </Stack>
        </LeftTextSection>
        <ImageBox sx={{ margin: "0 auto" }} item md={5.8} xs={12}>
          <img width="80%" src={studentillustration} alt="" />
        </ImageBox>
        <Grid
          item
          md={9}
          xs={11}
          sx={{
            margin: "0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Features />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Products;
