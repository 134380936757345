import React from "react";
import { Stack, Grid, Box } from "@mui/material";
import { FounderText, FounderTitle, ImgBox, Text, Title } from "./styles";
import line23 from "../../Images/Line 23.svg";
import teamIcon from "../../Images/Top Image.png";
import CEOImg from "../../Images/ali chaudhry.jpeg";
import SibghatImg from "../../Images/sibghat.jpg";
import LizyImg from "../../Images/lizy jacob.jpeg";

function Team() {
  return (
    <Box
      sx={{ p: { xs: "10px 15px", md: "20px 100px" }, background: "#FFFCF5" }}
    >
      <Stack
        marginTop="90px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <img width="100px" src={teamIcon} alt="" />
      </Stack>
      <Title sx={{ pt: 1 }}>Meet Our Team</Title>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <img width="30%" src={line23} alt="" />
      </Stack>
      <Text>There are the people who make the magic HAPPEN!</Text>
      <Grid container spacing={5} justifyContent="center">
        <Grid item md={3} xs={12}>
          <ImgBox>
            <img width="100%" src={CEOImg} alt="muhammad ali chaudhry" />
            <FounderTitle sx={{ pl: "5%" }}>Muhammad Ali Chaudhry</FounderTitle>
            <FounderText sx={{ pl: "5%", margin: "29px 0px" }}>CEO</FounderText>
          </ImgBox>
        </Grid>

        <Grid item md={3} xs={12}>
          <ImgBox>
            <img width="100%" src={LizyImg} alt="lizy jacob" />
            <FounderTitle sx={{ pl: "5%" }}>Lizy Jacob</FounderTitle>
            <FounderText sx={{ pl: "5%", margin: "29px 0px" }}>
              Head of AI
            </FounderText>
          </ImgBox>
        </Grid>
        <Grid item md={3} xs={12}>
          <ImgBox>
            <img width="100%" src={SibghatImg} alt="sibghatullah baig" />
            <FounderTitle sx={{ pl: "5%" }}>Sibghatullah Baig</FounderTitle>
            <FounderText sx={{ pl: "5%", margin: "29px 0px" }}>
              Head of Product
            </FounderText>
          </ImgBox>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Team;
