import { Box, Grid, styled, Typography } from "@mui/material";

export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "20px",
  textAlign: "center",
  color: "#1FB57F",
});

export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "40px",
  textAlign: "center",
  letterSpacing: "0.05em",
  padding: "2% 9%",
  color: "#2B2B2B",
});

export const FounderTitle = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: 600,
  fontSize: "20px",
  color: "#0D3C2B",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
export const FounderText = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "25px",
  color: "#0D3C2B",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
export const UserTitle = styled(Typography)({
  fontFamily: "Dosis",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "24px",
  color: "#252B42",
  letterSpacing: "0.1px",
});
export const UserText = styled(Typography)({
  fontFamily: "Montserrat",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "20px",
  color: "#737373",
  letterSpacing: "0.2px",
});

export const UserBox = styled(Box)({
  background: "#FFFFFF",
  borderBottom: "10px solid #FF2976",
  boxShadow: "-6px 13px 20px rgba(0, 0, 0, 0.16)",
  borderRadius: "16px",
});
export const ImgBox = styled(Box)({
  background: "#25DB9A",
  borderBottom: "12px dashed #F48C49",
  boxShadow: "-4px 4px 12px rgba(0, 0, 0, 0.25)",
  borderRadius: "16px",
  display: "inline-block",
  width: "100%",
});
export const CenterBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  borderBottom: "10px solid #FFC329",
  boxShadow: "-6px 13px 20px rgba(0, 0, 0, 0.16)",
  borderRadius: "16px",
  [theme.breakpoints.up("md")]: {
    marginTop: "50%",
  },
}));
