import React, { useState } from "react";
import { Box, Button, Stack, TextField } from "@mui/material";
import line23 from "../../Images/Line 23.svg";
import { Text, Title } from "./styles";

function ContactUs() {
  const [text, setText] = useState("");

  const handleInput = (e) => {
    setText(e.target.value);
  };

  return (
    <Box
      sx={{ pt: 10, pb: 10, width: "80%", height: "100vh", margin: "0 auto" }}
    >
      <Stack height="90%" justifyContent="space-evenly">
        <Title sx={{ pt: 1 }}>Contact Us</Title>
        <Stack width="100%" alignItems="center" justifyContent="center">
          <img width="30%" src={line23} alt="" />
        </Stack>
        <Text sx={{ margin: "50px 0px" }}>
          Provide your email address or phone number and our representative will
          get in contact with you as soon as possible.
        </Text>
        <Stack direction="row">
          <TextField
            sx={{
              background: "#FFFFFF",
              border: "1px solid #1FB57F",
              borderRadius: "10px",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  border: "1px solid #1FB57F",
                },
              },
            }}
            fullWidth
            placeholder="Email or Phone number here"
            value={text}
            onChange={handleInput}
          />
          <Button
            sx={{
              width: "20%",
              ml: -2,
              background: "#1FB57F !important",
              borderRadius: "10px",
            }}
            variant="contained"
          >
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ContactUs;
