import { Box, styled, Typography } from "@mui/material";
// import background from "../../images/footer-background.svg";

export const RootStyle = styled(Box)({
  // backgroundImage: `url(${background})`,
  width: "100%",
  // height: "120vh",
  // backgroundSize: "cover",
  // backgroundRepeat: "no-repeat",
  // bottom: 0,
  // left: 0,
  // right: 0,
  background: "#2E4E42",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
});
export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "left",
  color: "#1FB57F",
});
export const SubTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#1FB57F",
});
export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "30px",
  textAlign: "left",
  color: "#1FB57F",
  textDecorationLine: "underline",
});
export const IconsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  rowGap: 15,

  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
}));
export const Follow = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "left",
  color: "#1FB57F",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
}));
