import React from "react";
import { Box, Grid, Stack, styled } from "@mui/material";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { FeaturesTitle } from "./style";
import img1 from "../../Images/characteristic 1.png";
import img2 from "../../Images/financial-report 1.png";
import img3 from "../../Images/inventory 1.png";
import img4 from "../../Images/user-generated-content 1.png";
import icon1 from "../../Images/late-payment.png";
import icon2 from "../../Images/immigration.png";
import icon3 from "../../Images/report.png";
import icon4 from "../../Images/exam.png";
import icon5 from "../../Images/calendar.png";
import icon6 from "../../Images/journal.png";
import icon7 from "../../Images/student.png";
import icon8 from "../../Images/webinar.png";
import icon9 from "../../Images/marketing.png";
import icon10 from "../../Images/female.png";
import icon11 from "../../Images/accounting.png";
import icon12 from "../../Images/like 1.png";

const Text = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#6A6A6A",
  [theme.breakpoints.down("sm")]: {
    fontSize: "8px",
    lineHeight: "12px",
  },
}));
const GridBox = styled(Grid)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "20px 20px 20px 20px",
  marginBottom: "50px",
  [theme.breakpoints.down("md")]: {
    background: "#FFFBF4",
  },
}));
const ImgBox = styled(Box)({
  padding: "20%",
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "20px 20px 0px 0px",
});

function Features() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          height: "85px",
          background: "#25DB9A",
          borderRadius: "20px 20px 0px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 13%",
          wordWrap: "wrap",
        }}
      >
        <FeaturesTitle>
          Our Platform customised for your educational needs
        </FeaturesTitle>
      </Box>
      <GridBox container>
        <Grid item md={3} xs={6} sx={{}}>
          <Stack paddingTop="50px" rowGap={5}>
            <img
              style={{ margin: "0 auto" }}
              height={isMatch ? "80px" : "150px"}
              src={img1}
              alt=""
            />
            <Box sx={{ padding: "45px 0px", background: "#FFFBF4" }}>
              <Text>Content </Text>
              <Text> Management</Text>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={3} xs={6}>
          <Stack paddingTop="50px" rowGap={5}>
            <img
              style={{ margin: "0 auto" }}
              height={isMatch ? "80px" : "150px"}
              src={img2}
              alt=""
            />
            <Box sx={{ padding: "45px 0px", background: "#FFFBF4" }}>
              <Text>Financial</Text>
              <Text>Management</Text>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={3} xs={6}>
          <Stack paddingTop="50px" rowGap={5}>
            <img
              style={{ margin: "0 auto" }}
              height={isMatch ? "80px" : "150px"}
              src={img3}
              alt=""
            />
            <Box sx={{ padding: "45px 0px", background: "#FFFBF4" }}>
              <Text>Inventory </Text>
              <Text> Management</Text>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={3} xs={6}>
          <Stack paddingTop="50px" rowGap={5}>
            <img
              style={{ margin: "0 auto" }}
              height={isMatch ? "80px" : "150px"}
              src={img4}
              alt=""
            />
            <Box sx={{ padding: "45px 0px", background: "#FFFBF4" }}>
              <Text>HR </Text>
              <Text> Management</Text>
            </Box>
          </Stack>
        </Grid>
      </GridBox>
      <Grid container spacing={2}>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon1} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#FFFBF4",
            }}
          >
            <Text>Fee</Text>
            <Text>Collection</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon2} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#FFFBF4",
            }}
          >
            <Text>Attendance</Text>
            <Text>Marking</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon3} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#FFF3DE",
            }}
          >
            <Text>Report</Text>
            <Text>Generation</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon4} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#EDFFDE",
            }}
          >
            <Text>Weekly</Text>
            <Text>Time Table</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon5} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#FFFEDE",
            }}
          >
            <Text>Online Test</Text>
            <Text>& Classwork</Text>
          </Box>
        </Grid>

        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon6} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#E3DEFF",
            }}
          >
            <Text>Daily Diary</Text>
            <Text>& Lesson Plans</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon7} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#DEFFF5",
            }}
          >
            <Text>Student Profile</Text>
            <Text>Management</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon8} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#DEF7FF",
            }}
          >
            <Text>Class</Text>
            <Text>Management</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon9} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#DEE7FF",
            }}
          >
            <Text>Announcement</Text>
            <Text>& Alerts</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon10} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#DEFFE3",
            }}
          >
            <Text>Teacher</Text>
            <Text>Profile Management</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <ImgBox>
            <img height={isMatch ? "40px" : "100px"} src={icon11} alt="" />
          </ImgBox>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
              background: "#F2DEFF",
            }}
          >
            <Text>Expense Record</Text>
            <Text>& History</Text>
          </Box>
        </Grid>
        <Grid item md={3} xs={4}>
          <Box
            sx={{
              padding: "40% 20% 0px 20%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <img height={isMatch ? "40px" : "100px"} src={icon12} alt="" />
          </Box>
          <Box
            sx={{
              borderRadius: "0px 0px 20px 20px",
              padding: "10% 0px",
            }}
          >
            <Text>and much more .....</Text>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Features;
