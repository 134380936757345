import { Box, Stack, styled } from "@mui/material";
import React from "react";
import { Text, Title } from "./styles";
// import line23 from "../../images/Line 23.svg";
// import icons from "../../images/icons.png";
// import CogxBanner from "../../images/cogx banner.png";

const MainStyle = styled(Box)({
  background: "#FFFFFF",
  border: "1px solid #25DB9A",
  borderRadius: "250px 0px 250px 0px",
  minHeight: "600px",
});

function About() {
  return (
    <Box sx={{ padding: "5%", background: "#EEFFF9" }}>
      <MainStyle>
        <Stack
          marginTop="-15px"
          marginRight="-25px"
          direction="row"
          height="50px"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack alignItems="center" justifyContent="center">
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
          </Stack>
        </Stack>
        <Title sx={{ pt: 3 }}>About Us</Title>
        <Stack width="100%" alignItems="center" justifyContent="center">
          {/* <img width="30%" src={line23} alt="" /> */}
        </Stack>
        <Stack width="100%" alignItems="center" justifyContent="center">
          {/* <img height="200px" src={icons} alt="" /> */}
        </Stack>
        <Text>
          Naseem Education is a research-backed Ed-Tech startup working on the
          intersection of Artificial Intelligence and Education. We are
          utilising the latest developments in Artificial Intelligence,
          Neuroscience and Learning Sciences to empower teachers and learners
          with tools to optimise their teaching and learning experiences. We
          experiment, iterate and improve our products based on thorough user
          evaluations.
        </Text>
        <Stack
          marginBottom="-15px"
          marginLeft="-25px"
          direction="row"
          height="50px"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Stack alignItems="center" justifyContent="center">
            <Box
              sx={{
                width: "20px",
                height: "20px",
                background: "#FFC017",
                borderRadius: "2px",
                transform: "rotate(45deg)",
                zIndex: 1,
              }}
            ></Box>
            <Box
              sx={{
                width: "49px",
                height: "49px",
                background: "#FFEBB6",
                borderRadius: "10px",
                transform: "rotate(45deg)",
                zIndex: 0,
              }}
            ></Box>
          </Stack>
        </Stack>
      </MainStyle>
      <Box sx={{ maxWidth: "100%", mt: 15 }}>
        {/* <img width="100%" src={CogxBanner} alt="cogx-banner" /> */}
      </Box>
    </Box>
  );
}

export default About;
