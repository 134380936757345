import React from "react";
import { Stack } from "@mui/material";
import { styled, Typography, useTheme, useMediaQuery } from "@mui/material";
import Group122 from "../../Images/Group 40122.png";
import Group124 from "../../Images/Group 40124.png";
import Group125 from "../../Images/Group 40125.png";
import line23 from "../../Images/Line 23.svg";
import lighthouse from "../../Images/lighthouse.png";
import buraqschool from "../../Images/buraq school.png";
import chapterhouse from "../../Images/chapter house.png";
import planetsystem from "../../Images/planet systems.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#1FB57F",
});
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

function Partners() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack height="100vh" justifyContent="space-evenly">
      <>
        <Title sx={{ pt: 3 }}>Our Partners</Title>
        <Stack width="100%" alignItems="center" justifyContent="center">
          <img width="30%" src={line23} alt="" />
        </Stack>
      </>
      <Carousel
        swipeable={false}
        draggable={false}
        arrows={false}
        // showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        itemClass="carousel-item-padding-40-px"
        containerClass="carousel-container"
      >
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={Group122}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={Group124}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={Group125}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={lighthouse}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={chapterhouse}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={buraqschool}
            alt=""
          />
        </Stack>
        <Stack width="100%" justifyContent="center">
          <img
            style={{ margin: "0 auto" }}
            height={isMatch ? "80px" : "200px"}
            src={planetsystem}
            alt=""
          />
        </Stack>
      </Carousel>
    </Stack>
  );
}

export default Partners;
