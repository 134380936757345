import { styled, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "30px",
  textAlign: "center",
  color: "#1FB57F",
});

export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 400,
  fontSize: "20px",
  letterSpacing: "0.05em",
  color: "#2B2B2B",
});

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  //   flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "32px",
  // background: "#FBF8DB",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#60656C",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));
