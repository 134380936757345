import { Box, Grid, styled, Typography } from "@mui/material";
import background from "../../Images/footer-background.svg";

export const RootStyle = styled(Box)({
  backgroundImage: `url(${background})`,
  width: "100%",
  height: "120vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
});
export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#1FB57F",
});
export const FeaturesTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  textAlign: "center",
  lineHeight: "38px",
  color: "#ffffff",
  fontSize: "25px",
  [theme.breakpoints.down("sm")]: {
    lineHeight: "18px",
    fontSize: "12px",
  },
}));
export const SubTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#1FB57F",
});
export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "40px",
  textAlign: "center",
  color: "#1FB57F",
  padding: "5% 10%",
});
export const TextSection = styled(Grid)({
  background: "#FFFFFF",
  borderWidth: "1px 0px 1px 1px",
  borderStyle: "solid",
  borderColor: "#FFC017",
  minHeight: "439px",
  borderRadius: "30px 0px 0px 30px",
  wrap: "wrap",
  padding: "0px 3%",
});
export const LeftTextSection = styled(Grid)({
  background: "#FFFFFF",
  borderWidth: "1px 1px 1px 0px",
  borderStyle: "solid",
  borderColor: "#FFC017",
  minHeight: "439px",
  borderRadius: "0px 30px 30px 0px",
  padding: "0px 3%",
});
export const LImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  textAlign: "right",

  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));
export const ImageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const BoxList = styled(Box)({
  marginTop: "-25px",
  marginLeft: "40%",
  direction: "row",
  height: "50px",
  alignItems: "center",
});
