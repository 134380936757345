import { Box, styled, Typography } from "@mui/material";
import background from "../../Images/home-background.svg";

export const RootStyle = styled(Box)({
  backgroundImage: `url(${background})`,
  width: "100%",
  // height: "150vh",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "bottom",
  position: "relative",
});
export const MainStyle = styled(Box)({
  width: "80%",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
});

export const SubTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "33px",
  textAlign: "center",
  color: "#25DB9A",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "22px",
  lineHeight: "40px",
  textAlign: "center",
  color: "#25DB9A",
  textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
});
export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#1FB57F",
});
