import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { SubTitle, Text, Title } from "./styles";
import { Accordion, AccordionDetails, AccordionSummary } from "./styles";
import line23 from "../../Images/Line 23.svg";

function FAQs() {
  const [expanded, setExpanded] = useState(1);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ margin: "100px 0px", background: "#FFFFFF !important" }}>
      <Title sx={{ pt: 1 }}>Our FAQs</Title>
      <Stack width="100%" alignItems="center" justifyContent="center">
        <img width="30%" src={line23} alt="" />
      </Stack>
      <Text sx={{ margin: "50px 0px", textAlign: "center" }}>
        We listed most frequently asked questions from our clients below
      </Text>

      <Box sx={{ width: "80%", margin: "0 auto" }}>
        <Accordion expanded={expanded === 1} onChange={handleChange(1)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>Is the software available offline ?</SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Unfortunately no. Naseem education is an online cloud based system
              which requires internet connection.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 2} onChange={handleChange(2)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>Is there any mobile application available ?</SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Yes ! we have dedicated mobile applications for each role i.e
              executive, principal, teacher and student. Currently our mobile
              applications run on android platforms but we’ll develop for IOS
              platform soon.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 3} onChange={handleChange(3)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>
              Can we add multi campuses under one application ?
            </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Yes ! Using our Naseem Executive platform you will be able to add
              multiple campuses under single account. You will be able to
              monitor and control other accounts and details belonging to each
              campus.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 4} onChange={handleChange(4)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>Is it a multi user role based system ?</SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Yes ! but instead of a single platform we have dedicated web and
              mobile applications for each role as you can see in our products
              section.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 5} onChange={handleChange(5)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>What about customer support and data security ?</SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              We have awesome customer support team. Our representatives are
              available 24/7 to take your suggestions and complaints which helps
              our team to improve our products. Data security and privacy are
              one of our top priorities. Your data is stored on the cloud and
              can only be accessed by your accounts from anywhere.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 6} onChange={handleChange(6)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>Will we get any future update ?</SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Yes ! we constantly improve our products based on user feedback
              and new ways to solve user problems. You won’t have to do anything
              from your end. We handle updates from our end and notify our
              clients if major changes occur or new features added.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 7} onChange={handleChange(7)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>
              Is there any software demo available before purchase ?
            </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Yes ! you can contact us via email or phone number and get a brief
              demo from our customer support team before having to purchase your
              customised subscription plan. We’ll also provide demo videos
              inside our web and mobile applications soon to help clients
              navigate and understand different features.
            </Text>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 8} onChange={handleChange(8)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <SubTitle>
              Is there any other charges outside subscription plan ?
            </SubTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Text>
              Absolutely no ! We don’t charge anything outside of our
              subscription plans for you. Pricing for subscription plan is
              decided at the time of your discussion with our representatives.
            </Text>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}

export default FAQs;
