import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import Group199 from "../../Images/Group199.svg";
import arrowIcon from "../../Images/arrow.svg";
import Group40136 from "../../Images/Group 40136.svg";
import Group40135 from "../../Images/Group 40135.svg";
import Group40137 from "../../Images/Group 40137.svg";
import Group40138 from "../../Images/Group 40138.svg";
import Group40109 from "../../Images/Group 40109.svg";
import Group40111 from "../../Images/Group 40111.svg";
import Group40113 from "../../Images/Group 40113.svg";

import { MainStyle, RootStyle, SubTitle, Text, Title } from "./style";

function Home() {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <RootStyle>
      <MainStyle>
        <img
          src={Group199}
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 0,
            top: 0,
          }}
          alt=""
        />

        <img
          style={{ marginTop: "10%", zIndex: 1, width: "55%" }}
          src={Group40138}
          alt=""
        />
      </MainStyle>

      <SubTitle sx={{ pt: "30%" }}>
        A Management Information System powered by
      </SubTitle>
      <Title>Artificial Intelligence, Neuroscience </Title>
      <Title>& Learning Science</Title>
      <Box sx={{ width: "60%", margin: "0 auto" }} align="center">
        <Stack direction="row" justifyContent="space-between">
          <img
            style={{
              width: "20%",
              height: "20%",
              marginTop: isMatch ? "" : "-80px",
            }}
            src={Group40135}
            alt=""
          />
          <img
            style={{ width: "20%", height: "20%" }}
            src={Group40136}
            alt=""
          />
          <img
            style={{
              width: "20%",
              height: "20%",
              marginTop: isMatch ? "" : "-80px",
            }}
            src={Group40137}
            alt=""
          />
        </Stack>
        <img style={{ marginTop: "5%" }} src={arrowIcon} alt="" />
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "60%",
          mt: "8%",
          height: "45px",
          background: "#25DB9A",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.16)",
          borderRadius: "40px 40px 0px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SubTitle
          sx={{ textShadow: "none", fontWeight: 600, color: "#147C57" }}
        >
          Engage Learners With
        </SubTitle>
      </Box>
      <Grid
        container
        sx={{
          width: "70%",
          minHeight: "200px",
          margin: "0 auto",
          background: "#FFFFF5",
          boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.16)",
          borderRadius: "40px",
          // justifyContent: "center",
          alignItems: "center",
        }}
        justifyContent="center"
      >
        <Grid
          item
          md={4.5}
          sm={12}
          sx={{
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack height="100%" justifyContent="center">
            <img height="90px" src={Group40109} alt="" />
            <Text>Contexualized Learning</Text>
            <Text>Method</Text>
          </Stack>
        </Grid>
        <Grid
          item
          md={3}
          sm={12}
          sx={{
            width: "100%",
            minHeight: "200px",
            background: "#EEFFF9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack justifyContent="center">
            <img height="90px" src={Group40111} alt="" />
            <Text>Collaborative Learning </Text>
            <Text> Environment</Text>
          </Stack>
        </Grid>
        <Grid
          item
          md={4.5}
          sm={12}
          sx={{
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack justifyContent="center">
            <img height="90px" src={Group40113} alt="" />
            <Text>Automated Evaluations</Text>
            <Text> & Feedback</Text>
          </Stack>
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export default Home;
