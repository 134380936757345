import { useEffect, useState } from "react";
import About from "./component/about";
import ContactUs from "./component/contact";
import FAQs from "./component/faqs";
import Footer from "./component/footer";
import Home from "./component/home";
import Products from "./component/products";
import Team from "./component/team";

import MenuIcon from "@mui/icons-material/Menu";
import { CssBaseline, Divider, Drawer } from "@mui/material";
import { List, IconButton, ListItem, ListItemButton } from "@mui/material";
import { Button, AppBar, Box, Toolbar, Stack, styled } from "@mui/material";
 import NewLogo from "./Images/new logo.png";
import { Link } from "react-scroll";
import Partners from "./component/partners";
import AcceptCookiesForm from "./component/cookies";
import { useCookies } from "react-cookie";

const drawerWidth = 240;

const NavButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "27px",
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const [color, setColor] = useState("white");
  const [cookies] = useCookies(["user"]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const toggle = () => {
    setDemoModal((prev) => !prev);
  };

  const changeNavbar = () => {
    if (window.scrollY >= 400) {
      setNavbar(true);
      setColor("#25DB9A");
    } else {
      setNavbar(false);
      setColor("white");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <img height="50px" src={NewLogo} alt="" /> */}
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link to="home" spy={true} smooth={true} offset={0} duration={2000}>
              <NavButton sx={{ color: "black" }}>Home</NavButton>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="products"
              spy={true}
              smooth={true}
              offset={0}
              duration={2000}
            >
              <NavButton sx={{ color: "black" }}>Products</NavButton>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={2000}
            >
              <NavButton sx={{ color: "black" }}>About</NavButton>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="partners"
              spy={true}
              smooth={true}
              offset={0}
              duration={2000}
            >
              <NavButton sx={{ color: "black" }}>Partners</NavButton>
            </Link>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link to="team" spy={true} smooth={true} offset={0} duration={2000}>
              <NavButton sx={{ color: "black" }}>Team</NavButton>
            </Link>
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={0}
              duration={2000}
            >
              <NavButton sx={{ color: "black" }}>Contact Us</NavButton>
            </Link>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.youtube.com/watch?v=hZXEbU3Ncqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                sx={{
                  textTransform: "none",
                  background: "#25DB9A !important",
                }}
                variant="contained"
              >
                Demo
              </Button>
            </a>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;
  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", position: "relative", top: 0 }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={
            navbar
              ? { background: "white", color: "black" }
              : { boxShadow: "none", background: "transparent" }
          }
        >
          <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={{ display: { sm: "none" } }}>
              <img style={{ height: "2.5rem" }} src={NewLogo} alt="" />
            </Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: "flex",
                justifyContent: "end",
                right: 2,
                position: "absolute",
                display: { sm: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ width: "100%", display: { xs: "none", sm: "block" } }}>
              <Stack
                width="100%"
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>Home</NavButton>
                  </Link>
                  <Link
                    to="products"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>Products</NavButton>
                  </Link>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>About</NavButton>
                  </Link>
                </Box>
                <Box
                  sx={{
                    width: "10%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img style={{ height: "5rem" }} src={NewLogo} alt="" />
                </Box>
                <Box
                  sx={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="partners"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>Partners</NavButton>
                  </Link>
                  {/* <Link
                    to="team"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>Team</NavButton>
                  </Link> */}
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={2000}
                  >
                    <NavButton sx={{ color: color }}>Contact Us</NavButton>
                  </Link>
                  <a
                    style={{ textDecoration: "none" ,marginLeft:"8px"}}
                    href="https://www.youtube.com/watch?v=hZXEbU3Ncqs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      sx={{
                        textTransform: "none",
                        background: "#25DB9A !important",
                      }}
                      variant="contained"
                    >
                      Demo
                    </Button>
                  </a>
                </Box>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            // ModalProps={{
            //   keepMounted: true,
            // }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>

      <Box id="home">
        <Home />
      </Box>

      <Box id="products">
        <Products />
      </Box>
      <Box id="about">
        <About />
      </Box>
      <Box id="partners">
        <Partners />
      </Box>

      {/* <Box id="team">
        <Team />
      </Box> */}
      <FAQs />
      <Box id="contact">
        <ContactUs />
      </Box>
      <Footer />
      {!cookies?.user && (
        <Box sx={{ top: "75vh", position: "fixed" }}>
          <AcceptCookiesForm />
        </Box>
      )}
    </Box>
  );
}

export default App;
