import React from "react";
import { Grid } from "@mui/material";
import footer1 from "../../Images/footer1.svg";
import Logo from "../../Images/new logo.png";
import footer3 from "../../Images/footer3.svg";
import fbIcon from "../../Images/fbIcon.svg";
import linkedInIcon from "../../Images/linkedInIcon.svg";
import { IconsBox, RootStyle, SubTitle, Text, Title, Follow } from "./style";

function Footer() {
  return (
    <RootStyle>
      <Grid
        padding="0% 5%"
        paddingTop="80px"
        container
        spacing={1}
        align="center"
      >
        <Grid item xs={4}>
          <img width="50%" src={footer1} alt="" />
        </Grid>
        <Grid item xs={4}>
          <img width="30%" src={Logo} alt="" />
        </Grid>
        <Grid item xs={4}>
          <img width="30%" src={footer3} alt="" />
        </Grid>
      </Grid>
      <Grid
        paddingTop="10%"
        paddingLeft="10%"
        paddingRight="10%"
        paddingBottom="2%"
        container
        spacing={1}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item md={4} xs={6}>
          <Title>Location</Title>
          <Text>Gower St, London WC1E 6BT,</Text>
          <Text>London, EC2A 2BB</Text>
          <Text>United Kingdom</Text>
          <Text>+44 7403 228448</Text>
        </Grid>
        {/* <Grid item md={5} xs={6}>
          <Title>Top Features</Title>
          <Text>Content Management</Text>
          <Text>Financial anagement</Text>
          <Text>Inventory Management</Text>
          <Text>HR Management</Text>
        </Grid> */}
        <Grid item md={4} xs={6}>
          <Text sx={{ textDecoration: "none" }}>
            For any customer inquiries please contact sibghat@naseem.education
          </Text>
          <Text sx={{ margin: "50px 0px", textDecoration: "none" }}>
            We are a research-backed startup using artificial intelligence to
            transform the education systems globally
          </Text>
          <Text sx={{ margin: "50px 0px", textDecoration: "none" }}>
            2024 Copyright : Naseem Education
          </Text>
        </Grid>
        <Grid
          item
          md={4}
          xs={6}
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Follow>Follow Us</Follow>
          <IconsBox>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.facebook.com/naseemeducation"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img height="35px" src={fbIcon} alt="" />
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.linkedin.com/company/naseem/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img height="35px" src={linkedInIcon} alt="" />
            </a>
          </IconsBox>
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export default Footer;
