import { styled, Typography } from "@mui/material";

export const Title = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "30px",
  lineHeight: "45px",
  textAlign: "center",
  color: "#1FB57F",
});

export const Text = styled(Typography)({
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "40px",
  textAlign: "center",
  letterSpacing: "0.05em",
  padding: "2% 9%",
  color: "#2B2B2B",
});
